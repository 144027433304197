import React, {useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import Home from "./components/Home";
import AppNotInstalled from "./components/AppNotInstalled";
import {Button, Row} from "react-bootstrap";

function checkJWT() {
    const token = Cookies.get('jwt');
    return !!token;
}

function App() {
    const [isAuth, setIsAuth] = useState(checkJWT());

    useEffect(() => {
        setIsAuth(checkJWT());
    }, []);

    const handleLogout = () => {
        Cookies.remove('jwt');
        setIsAuth(false);
    };

    return (
        <div className="App">
            <div className='container'>
                <div className="card p-4">
                    {isAuth ? (
                        <div className='container'>
                            <Row>
                                <Home/>
                            </Row>
                            <Row>
                                <div className='col-2 ms-auto'>
                                    <Button variant='danger' onClick={handleLogout}>Logout</Button>
                                </div>
                            </Row>
                        </div>
                    ) : (
                        <AppNotInstalled/>
                    )}
                </div>
            </div>
        </div>
    );
}

export default App;
