import React, {useEffect, useState} from 'react';
import Subscribe from "./Subscribe";
import Settings from "./Settings";

function Home() {
    const [subscription, setSubscription] = useState(null);

    useEffect(() => {
        //Fetch subscription from the server
        fetch('/subscriptions/status')
            .then(res => res.json())
            .then(json => {
                console.log('subscription', json);
                console.log('subscription', json.data.subscription);
                setSubscription(json.data.subscription);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    return (
        <div className="container">
            {subscription ? <Settings subscription={subscription}/> : <Subscribe/>}
        </div>
    )
}

export default Home;

