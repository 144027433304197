import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Form, Tab, Tabs} from 'react-bootstrap';
import {WebhookUrl} from "./WebhookUrl";
import SubscriptionTab from "./SubscriptionTab";

function Settings(props) {
    const [settings, setSettings] = useState(null);
    const [users, setUsers] = useState([]);
    const [showSecret, setShowSecret] = useState(false);
    const [assignee, setAssignee] = useState(null)
    const subscription = props.subscription;

    useEffect(() => {
        async function fetchData() {
            const response = await fetch('/settings');
            const settings = await response.json();

            const usersResponse = await fetch('/settings/owners');
            const users = await usersResponse.json();

            setUsers(users);
            setSettings(settings);
            setAssignee(users.find(user => user.id.toString() === settings.taskAssignee.toString()));
        }

        fetchData();
    }, []);

    const toggleSecret = () => setShowSecret(!showSecret);
    const handleAssigneeSelect = (selectedId) => {
        const user = users.find(user => user.id === selectedId);
        if (!user) return;
        setAssignee(user)
        setSettings(prevSettings => ({
            ...prevSettings,
            taskAssignee: user.id
        }));
    };

    const handleSubmit = event => {
        event.preventDefault();

        fetch('/settings', {
            method: 'PUT',
            body: JSON.stringify(settings),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(result => {
                console.log('Success:', result);
                alert('Settings saved!');
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handlePrioritySelect = (selectedPriority) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            taskPriority: selectedPriority
        }));
    };

    const handleSecretChange = (event) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            solvariSecret: event.target.value
        }));
    };

    const handleIntervalChange = (event) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            taskInterval: event.target.value
        }));
    };

    return (
        <div className='container-fluid mt-4'>
            <h1>Settings</h1>
            <Form onSubmit={handleSubmit}>
                <div className='container'>
                    <Tabs className='container mt-3' fill>
                        <Tab eventKey="hubspot" title="HubSpot">
                            <div className='row mt-3'>
                                <div className='col-6'>
                                    <Form.Group>
                                        <Form.Label>Task Assignee</Form.Label>
                                        <Dropdown onSelect={handleAssigneeSelect}>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                {assignee ? `${assignee.firstName} ${assignee.lastName}` : 'Select Assignee'}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {users.map((user, index) => (
                                                    <Dropdown.Item key={index}
                                                                   eventKey={user.id}>{user.firstName} {user.lastName}</Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Form.Group>
                                </div>
                                <div className='col-6'>
                                    <Form.Label>Task Priority</Form.Label>
                                    <Dropdown onSelect={handlePrioritySelect}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {settings?.taskPriority || 'Select Priority'}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="Low">Low</Dropdown.Item>
                                            <Dropdown.Item eventKey="Medium">Medium</Dropdown.Item>
                                            <Dropdown.Item eventKey="High">High</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-6'>
                                    <Form.Group>
                                        <Form.Label>Task Interval (s)</Form.Label>
                                        <Form.Control type="number" placeholder="Enter task interval in seconds"
                                                      onChange={handleIntervalChange}
                                                      defaultValue={settings?.taskInterval}/>
                                    </Form.Group>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="solvari" title="Solvari">
                            {settings?.webhookUrl && (
                                <div className='row mt-3'>
                                    <div className='col-12'>
                                        <WebhookUrl webhookUrl={settings.webhookUrl}/>
                                    </div>
                                </div>
                            )}
                            <div className='row mt-3'>
                                <div className='col-6'>
                                    <Form.Group>
                                        <Form.Label>Solvari Secret</Form.Label>
                                        <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                                            <Form.Control style={{paddingRight: '80px'}}
                                                          type={showSecret ? 'text' : 'password'}
                                                          defaultValue={settings?.solvariSecret} name="solvariSecret"
                                                          onChange={handleSecretChange}/>
                                            <Button variant="outline-secondary" onClick={toggleSecret}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '0px',
                                                        top: '25%',
                                                        transform: 'translateY(-25%)'
                                                    }}
                                                    className="ml-2">{showSecret ? 'Hide' : 'Show'}</Button>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="subscription" title="Subscription">
                            <SubscriptionTab subscription={subscription}/>
                        </Tab>
                    </Tabs>
                </div>


                <div className='container-fluid mt-3'>
                    <div className='col-3 me-auto'>
                        <Button variant="primary" type="submit"> Save</Button>
                    </div>
                </div>
            </Form>
        </div>

    );
}

export default Settings;
