import React from "react";
import {Button, Row} from "react-bootstrap";

export function WebhookUrl(props) {
    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(props.webhookUrl);
        alert('Webhook URL copied to clipboard!');
    };

    return <div className="col-12">
        <div className="container">
            <Row>
                <div className='col-8'>
                    <p>Webhook URL:</p>
                    <p>{props.webhookUrl}</p>
                </div>
                <div className='col-4'>
                    <Button onClick={copyToClipboard} variant={"secondary"}>Copy to Clipboard</Button>
                </div>
            </Row>

        </div>
    </div>;
}