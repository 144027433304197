import React from 'react';

const AppNotInstalled = () => (
    <div className="container">
        <div className='row justify-content-center'>
            <div className='col-4'>
                <h1>App not installed</h1>
                <a href="/install">Click here to install</a>
            </div>
        </div>
    </div>
);

export default AppNotInstalled;
