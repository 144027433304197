import {Button, Modal, Tab} from "react-bootstrap";
import React, {useState} from "react";
import Cookies from "js-cookie";

function SubscriptionTab(props) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    console.log(this, props.subscription);

    const cancelSubscription = () => {
        fetch('/subscriptions', {
            method: 'DELETE',
        })
            .then(response => response.json())
            .then(result => {
                console.log('Success:', result);
                Cookies.remove('jwt');
                alert('Subscription canceled!');
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        window.location.reload();
    }

    return <Tab.Content>
        <div className="row mt-3">
            <div className="col-6">
                Your subscription is active until: {props.subscription?.startDate}
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-6">
                <Button variant={"danger"} onClick={handleShow}>Cancel subscription</Button>
            </div>
        </div>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Cancel subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to cancel your subscription?</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    No
                </Button>
                <Button variant="danger" onClick={cancelSubscription}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    </Tab.Content>
}

export default SubscriptionTab;