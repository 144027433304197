import React from "react";
import {Button} from "react-bootstrap";

function Subscribe() {
    async function createSubscription() {
        try {
            const response = await fetch('/subscriptions/subscribe', {
                method: 'POST',
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data?.data?.paymentData && data.data.paymentData._links) {
                window.location.href = data.data.paymentData._links.checkout.href;
            } else {
                console.log('Missing paymentData or _links');
            }
        } catch (error) {
            console.error('Fetch Error:', error);
        }
    }

    return (
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-6'>
                    <h3>Subscribe</h3>
                    <p> No active subscription found for this portal </p>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-6'>
                    <Button onClick={createSubscription}>Subscribe</Button>
                </div>
            </div>
        </div>
    )
}

export default Subscribe;